// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = window.$ = require('jquery');
window.Popper = require('popper.js')

require("@rails/ujs").start()
require("turbolinks").start()
// require("channels")

// >>>>>>>>>>  Plugins  <<<<<<<<<<
// Enable Fancybox
import initFancyBox from "../website/plugins/fancybox";

// Bootstrap
import "bootstrap";

// Ícones
import "@fortawesome/fontawesome-free/js/all";

// JS - Toast
import Swal from 'sweetalert2';

// Carrosséis
import {
  destroyCarousel,
  initCarouselBanner,
  initCarouselEvents,
  initCarouselCampaigns,
  initCarouselSponsors,
  initCarouselTimelime,
  initTwoRowsCarousel,
  initCarouselAwards,
  initCarouselArticle
} from '../website/plugins/slick-carousel';

import recaptchaCallback from '../website/components/recaptcha';

import {
  initDocumentModal
} from '../website/components/modals';

import filterArticles from '../website/components/filter-associates';
import favoriteArticle from '../website/components/favoriteArticle';
import articleLinkShare from '../website/components/articleLinkShare';

import setSameHeight from '../website/utils/set-same-height';
import initLinkAnchor from '../website/utils/link-anchor';

// >>>>>>>>>>  Custom CSS  <<<<<<<<<<
require('../website/stylesheets/website.sass')

// >>>>>>>>>>  Custom JS  <<<<<<<<<<
import initMasks from '../website/plugins/jquery-mask-plugin';

// Dispara evento quando carregar a pagina
document.addEventListener("turbolinks:load", (event) => {
  window.jQuery('[data-toggle="tooltip"]').tooltip()
  window.jQuery('[data-toggle="popover"]').popover()

  initMasks();

  // Carrosséis
  initCarouselBanner();
  initCarouselEvents();
  initCarouselCampaigns();
  initCarouselSponsors();
  initCarouselTimelime();
  initTwoRowsCarousel();
  initCarouselArticle();
  initCarouselAwards();

  recaptchaCallback();

  initDocumentModal();

  initFancyBox();

  initLinkAnchor();

  filterArticles();
  favoriteArticle();
  articleLinkShare();


  if (event.data.timing.visitStart) {
    // Executado somente quando a pagina for carregada pelo turbolinks
    setSameHeight('element-timeline')
    setSameHeight('element-boards')
    setSameHeight('element-presidents')
  }
})

$(window).on('load', () => {
  // Executado somente na primeira vez que a pagina for carregada (sem turbolink)
  setSameHeight('element-timeline')
  setSameHeight('element-boards')
  setSameHeight('element-presidents')
})

document.addEventListener("turbolinks:before-cache", () => {
  destroyCarousel();
})

window.Swal = Swal;
window.recaptchaCallback = recaptchaCallback;
