// DOC: https://igorescobar.github.io/jQuery-Mask-Plugin/docs.html
import 'jquery-mask-plugin/dist/jquery.mask';

const initMasks = () => {

  // var handleCpfCnpj = function (val) {
  //   const handleCpfCnpjMasks = ['000.000.000-009', '00.000.000/0000-00'];
  //   return (val.length <= 14) ? handleCpfCnpjMasks[0] : handleCpfCnpjMasks[1];
	// };
	// var cpfCnpjpOptions = {
	// 	onKeyPress: function(val, e, field, options) {
	// 		field.mask(handleCpfCnpj.apply({}, arguments), options);
	// 	}
	// };

  $('.date').mask('00/00/0000');
  $('.time').mask('00:00:00');
  $('.date_time').mask('00/00/0000 00:00:00');
  $('.cep').mask('00000-000');
  $('.phone').mask('(00) 00000-0000');
  $('.mixed').mask('AAA 000-S0S');
  $('.cpf').mask('000.000.000-00', {reverse: true});
  $('.cnpj').mask('00.000.000/0000-00', {reverse: true});
  $('.money').mask('000.000.000.000.000,00', {reverse: true});
  $('.money2').mask("#.##0,00", {reverse: true});
  $('.ip_address').mask('099.099.099.099');
  $('.percent').mask('##0,00%', {reverse: true});
  // $('.cpf_cnpj').mask(handleCpfCnpj, cpfCnpjpOptions);
  $('.rg').mask('00.000.000-0', {reverse: true});
}

export default initMasks;