import path from 'path';

export const initDocumentModal = () => {
  $('#document-modal').on('show.bs.modal', (e) => {
    let $modal = $(e.currentTarget);
    let $button = $(e.relatedTarget);

    let file = $button.data('file');
    let fileEXT = path.extname(file);
    let element = "";

    if (fileEXT == '.pdf'){
      element = `<iframe src="${file}"/>`
    } else {
      element = `<img src="${file}"/>`
    }

    $modal.find('.modal-body').html(element);
  })
}