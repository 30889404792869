// DOCS: https://kenwheeler.github.io/slick/
import "slick-carousel";

export const initCarouselBanner = () => {
  if ( !$('#slick-carousel-banner-home').hasClass('slick-initialized') ) {
    $('#slick-carousel-banner-home').slick({
      infinite: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      dotsClass: "slick-dots vertical-slick-dots"
    });
  }
}

export const initCarouselEvents = () => {
  if ( !$('#slick-carousel-events').hasClass('slick-initialized') ){
    const carouselEvents = $('#slick-carousel-events');

    carouselEvents.on('init', () => {
      resizeCarousel( carouselEvents );
    });

    window.addEventListener('resize', () => {
      resizeCarousel( carouselEvents );
    });

    carouselEvents.slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      variableWidth: true,

      arrows: true,
      prevArrow: '<div class="btn-arrow prev"><span class="icon material-icons">arrow_back_ios<span></div>',
      nextArrow: '<div class="btn-arrow next"><span class="icon material-icons">arrow_forward_ios<span></div>',

      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
          }
        }
      ]
    });
  }
}

export const initCarouselCampaigns = () => {
  if ( !$('#slick-carousel-campaigns').hasClass('slick-initialized') ){
    const carouselCampaigns = $('#slick-carousel-campaigns');

    carouselCampaigns.on('init', () => {
      resizeCarousel( carouselCampaigns );
    });

    window.addEventListener('resize', () => {
      resizeCarousel( carouselCampaigns );
    });

    carouselCampaigns.slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      variableWidth: true,

      arrows: true,
      prevArrow: '<div class="btn-arrow prev"><span class="icon material-icons">arrow_back_ios<span></div>',
      nextArrow: '<div class="btn-arrow next"><span class="icon material-icons">arrow_forward_ios<span></div>',

      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
          }
        }
      ]
    });
  }
}

export const initCarouselSponsors = () => {
  if ( !$('#slick-carousel-sponsors').hasClass('slick-initialized') ) {
    $('#slick-carousel-sponsors').slick({
      infinite: true,
      dots: false,

      arrows: true,
      prevArrow: '<div class="btn-arrow prev"><span class="icon material-icons">arrow_back_ios<span></div>',
      nextArrow: '<div class="btn-arrow next"><span class="icon material-icons">arrow_forward_ios<span></div>',

      slidesToShow: 4,
      slidesToScroll: 4,

      responsive: [
        {
          breakpoint: 1200, // NOTEBOOKS
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        }, {
          breakpoint: 990, // TABLET
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }, {
          breakpoint: 770, // MOBILE
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }
}

export const initCarouselTimelime = () => {
  if (!$('#slick-carousel-timeline').hasClass('slick-initialized')){
    $('#slick-carousel-timeline').slick({
      infinite: false,
      dots: false,

      arrows: true,
      prevArrow: '<div class="btn-arrow prev"><span class="icon material-icons">arrow_back_ios<span></div>',
      nextArrow: '<div class="btn-arrow next"><span class="icon material-icons">arrow_forward_ios<span></div>',

      slidesToShow: 5,
      slidesToScroll: 5,

      responsive: [
        {
          breakpoint: 1200, // NOTEBOOK
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },{
          breakpoint: 990, // TABLET
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }, {
          breakpoint: 770, // MOBILE
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    })
  }
}

export const initTwoRowsCarousel = () => {
  if (!$('.slick-two-rows-carousel').hasClass('slick-initialized')){
    $('.slick-two-rows-carousel').slick({
      infinite: false,
      dots: false,

      arrows: true,
      prevArrow: '<div class="btn-arrow prev"><span class="icon material-icons">arrow_back_ios<span></div>',
      nextArrow: '<div class="btn-arrow next"><span class="icon material-icons">arrow_forward_ios<span></div>',

      rows: 2,
      slidesToShow: 4,
      slidesToScroll: 4,

      responsive: [
        {
          breakpoint: 990, // TABLET
          settings: {
            rows: 1,
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }, {
          breakpoint: 770, // MOBILE
          settings: {
            rows: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    })
  }
}

// export const initCarouselAwards = () => {
//   if (!$('#slick-carousel-awards').hasClass('slick-initialized')){
//     $('#slick-carousel-awards').slick({
//       infinite: false,
//       dots: false,

//       arrows: true,
//       prevArrow: '<div class="btn-arrow prev"><span class="icon material-icons">arrow_back_ios<span></div>',
//       nextArrow: '<div class="btn-arrow next"><span class="icon material-icons">arrow_forward_ios<span></div>',

//       slidesToShow: 1,
//       slidesToScroll: 1,
//     })
//   }
// }

export const initCarouselArticle = () => {
  if ( !$('#slick-carousel-article').hasClass('slick-initialized') ) {
    $('#slick-carousel-article').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      prevArrow: '<div class="btn-arrow prev"><span class="icon material-icons">arrow_back_ios<span></div>',
      nextArrow: '<div class="btn-arrow next"><span class="icon material-icons">arrow_forward_ios<span></div>',
      fade: true,
      asNavFor: '#slick-carousel-article-miniature'
    })
  }

  if ( !$('#slick-carousel-article-miniature').hasClass('slick-initialized') ) {
    $('#slick-carousel-article-miniature').slick({
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      draggable: false,
      arrows: false,
      focusOnSelect: true,
      asNavFor: '#slick-carousel-article',
      responsive: [
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    })
  }
}

export const initCarouselAwards = () => {
  if ( !$('#slick-carousel-awards').hasClass('slick-initialized') ){
    const carouselCampaigns = $('#slick-carousel-awards');

    carouselCampaigns.on('init', () => {
      resizeCarousel( carouselCampaigns );
    });

    window.addEventListener('resize', () => {
      resizeCarousel( carouselCampaigns );
    });

    carouselCampaigns.slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      variableWidth: true,

      arrows: true,
      prevArrow: '<div class="btn-arrow prev"><span class="icon material-icons">arrow_back_ios<span></div>',
      nextArrow: '<div class="btn-arrow next"><span class="icon material-icons">arrow_forward_ios<span></div>',

      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
          }
        }
      ]
    });
  }
}


const resizeCarousel = (carousel) => {
  var wCarousel = carousel.width();
  var wBiggerSlide = 46; // 46% da largura do carrossel
  var wSmallerSlide = (100 - wBiggerSlide) / 2; // 27% da largura do carrossel

  $('.bigger-slide').width((wCarousel * wBiggerSlide)/100);
  $('.smaller-slide').width((wCarousel * wSmallerSlide)/100);
}

export const destroyCarousel = () => {
  $('#slick-carousel-banner-home').slick('unslick');
  $('#slick-carousel-events').slick('unslick');
  $('#slick-carousel-campaigns').slick('unslick');
  $('#slick-carousel-sponsors').slick('unslick');
  $('#slick-carousel-timeline').slick('unslick');
  $('.slick-two-rows-carousel').slick('unslick');
  // $('#slick-carousel-awards').slick('unslick');
  $('#slick-carousel-article').slick('unslick');
  $('#slick-carousel-article-miniature').slick('unslick');
  $('#slick-carousel-awards').slick('unslick');
}
