const initLinkAnchor = () => {
	$('a.link-anchor').on('click', ((event) => {
    var element = $(event.target).data('target');
    var time = 1000;
    var offset = 150;

    $('html, body').animate({ scrollTop: $(element).offset().top - offset }, time);
	}));
}

export default initLinkAnchor;