import ClipboardJS from 'clipboard';

const articleLinkShare = () => {
  $('.link-schare').click( event => {
    event.preventDefault();

    const clipboard = new ClipboardJS('.link-schare', {
      container: document.getElementById('modal-share')
    });

    clipboard.on('success', () => {
      $('#modal-share .copy-text').addClass("active");
    });
  })
}

export default articleLinkShare;
