const resetSameHeight =  (selector) => {
  $(`[data-sheight="${selector}"]`).css('min-height', 'auto');
}

const setSameHeight = (selector, offset) => {
  resetSameHeight(selector);

  let highHeight = 0;
  if (offset == undefined){
    offset = 0;
  }

  $.map( $(`[data-sheight="${selector}"]`), (obj) => {
    if ( highHeight < $(obj).innerHeight() ){
      highHeight = $(obj).innerHeight();
    }
  });

  $(`[data-sheight="${selector}"]`).css('min-height', `${offset + highHeight}px`);
}

export default setSameHeight;