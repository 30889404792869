/*
  DOC: https://fancyapps.com/fancybox/3/docs
*/
import '@fancyapps/fancybox/dist/jquery.fancybox.js'

const initAppFancyBox = () => {
 $("[data-fancybox]").fancybox({
   buttons: [
    'zoom',
    'slideShow',
    'fullScreen',
    'thumbs',
    'close'
   ],
   lang : 'pt-BR',
   protect: true,
   i18n : {
     'pt-BR' : {
       ZOOM : 'Zoom',
       PLAY_START: 'Apresentação de slides',
       FULL_SCREEN: 'Tela cheia',
       THUMBS: 'Miniaturas',
       CLOSE : 'Fechar',
       NEXT: 'Próximo',
       PREV: 'Anterior'
     }
   }
 });
}

export default initAppFancyBox