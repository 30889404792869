const ajaxUrl = '/ajax/filter_associates'

const filterArticles = () => {
  $('#filter-associates').off().on('submit', e => {
    e.preventDefault();
    e.stopPropagation();

    let data = {
      search: $('#search_associates_company_name').val()
    }

    $.ajax({
      url: ajaxUrl,
      method: 'post',
      dataType: 'script',
      data: data,
    }).done(
      () => {
        $.each(data, (key, value) => { if(!value) delete data[key] });
        history.replaceState(data, "Filtrando Associados", `${ window.location.pathname }?${ $.param(data) }` );
      }
    );
  })
}

export default filterArticles