import Cookies from 'js-cookie'
import isPresent from '~/utils/isPresent';
import isBlank from '~/utils/isBlank';

const jsCookies = Cookies.withConverter({
  read: value => {
    return JSON.parse(value);
  },
  write: value => {
    return JSON.stringify(value);
  }
})

const findFavoriteArticle = slug => {
  const cookies = jsCookies.get('FavoriteArticles');
  let favoriteArticles;

  if ( isPresent(cookies) ) {
    favoriteArticles = cookies.find(cookieSlug => cookieSlug == slug)
  }

  return favoriteArticles || null
}

const addFavoriteArticle = slug => {
  const cookies = jsCookies.get('FavoriteArticles') || [];
  const newCookies = cookies.concat(slug)

  jsCookies.set('FavoriteArticles', newCookies);
}

const addFavoriteCallback = (element) => {
  $(element).find('.icon').html('favorite');
  $('#favorite-filter').removeClass('d-none')
}

const removeFavoriteArticle = slug => {
  const cookies = jsCookies.get('FavoriteArticles');
  const newCookies = cookies.filter(cookieSlug => cookieSlug != slug)

  jsCookies.set('FavoriteArticles', newCookies);
}

const removeFavoriteCallback = (element) => {
  $(element).find('.icon').html('favorite_border');

  if ( isBlank(jsCookies.get('FavoriteArticles')) ){
    $('#favorite-filter').addClass('d-none')
  }
}

const initFavoriteArticle = () => {
  $('[data-favorite-article]').click( event => {
    const dataElement = $(event.currentTarget).data('favorite-article');
    const favoriteArticle = findFavoriteArticle(dataElement);

    if ( isPresent(favoriteArticle) ){
      removeFavoriteArticle(dataElement);
      removeFavoriteCallback(event.currentTarget);
    } else {
      addFavoriteArticle(dataElement);
      addFavoriteCallback(event.currentTarget);
    }
  })
}

export default initFavoriteArticle;
